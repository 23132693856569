import { IonBadge, IonButton, IonCol, IonContent, IonFooter, IonHeader, IonIcon, IonModal, IonRow, IonSpinner, IonText, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import { car, cartOutline, closeCircle } from "ionicons/icons";
import { useRef, useState } from "react";
import CartBody from "./CartBody";
import "./CartModal.scss";
import { useAppContext } from "../../services/app-context.service";
import CheckoutBody from "./CheckoutBody";
import { authModalRef } from "../Auth/AuthModal";
import shoppingCartService from "../../services/shop/cart.service";
import CartSubTotal from "./CartSubTotal";

const BDT = new Intl.NumberFormat('en-IN');
export let checkoutModalRef: React.RefObject<HTMLIonModalElement>
export let cartModalRef: React.RefObject<HTMLIonModalElement>
const CartModal: React.FC = () => {
    cartModalRef = useRef<HTMLIonModalElement>(null)
    checkoutModalRef = useRef<HTMLIonModalElement>(null)
    const { user, cart } = useAppContext()
    const [presentToast] = useIonToast();
    const [loading, setLoading] = useState(false)
    function onCheckout() {
        if (cart.items.length < 1) {
            checkoutModalRef.current?.present()
            return
        }
        setLoading(true)
        shoppingCartService.verifyStock().then(() => {
            cartModalRef.current?.dismiss().then(() => {
                checkoutModalRef.current?.present()
            })
        }).catch((errPromise: Promise<string>) => {
            errPromise.then((errText) => {
                presentToast({
                    message: errText ? errText : "Something Went Wrong.",
                    duration: 3000,
                    cssClass: 'custom-toast',
                    animated: true,
                    color: "danger",
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ],
                })
            })
        }).finally(() => {
            setLoading(false)
        })
    }
    return <>
        <IonButton fill='clear' id="btn-min-cart" onClick={() => cartModalRef.current?.present()}>
            <IonIcon size='large' icon={cartOutline} />
            {cart.itemCount > 0 && <IonBadge slot="end">{cart.itemCount}</IonBadge>}
        </IonButton>
        <IonModal ref={cartModalRef} id='cart-modal'>
            <IonHeader>
                <IonToolbar color="light">
                    <IonTitle>Shopping Cart</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light" className='ion-padding' style={{ overflow: "auto" }}>
                <CartBody />
            </IonContent>
            <IonFooter>
                <CartSubTotal />
                {cart.subTotal > 0 && <IonRow>
                    {!user.token && !loading && <IonCol>
                        <IonButton onClick={() => authModalRef.current?.present()} color="success" expand="block">Login / Register</IonButton>
                    </IonCol>}
                    <IonCol>
                        <IonButton disabled={loading} onClick={onCheckout} color="primary" expand="block">
                            {loading ? <>
                                <IonSpinner style={{ marginRight: 20 }} />
                                <IonText>Verifying Stock</IonText>
                            </> : <>
                                অর্ডার করতে আপনার তথ্য দিন
                            </>}
                        </IonButton>
                    </IonCol>
                </IonRow>}
                {window.screen.width <= 600 && <IonButton fill='clear' expand="block" onClick={() => cartModalRef.current?.dismiss()}>
                    <IonIcon slot="start" icon={closeCircle} /> Close
                </IonButton>}
            </IonFooter>
        </IonModal>
        <IonModal ref={checkoutModalRef} id='checkout-modal'>
            <CheckoutBody />
        </IonModal>
    </>
};
export default CartModal
