import { Link } from 'react-router-dom';
import { appSubDir, baseUrl } from '../../configs/app';
import { useTranslation } from 'react-i18next';
import { fetchAllAuthors, getAllAuthors } from '../../services/products/all-authors.service';
import { useEffect, useState } from 'react';
import './AuthorSwiperVerticle.scss'

const AuthorSwiperVerticle: React.FC = () => {
    const { t } = useTranslation();
    const [items, setItems] = useState(getAllAuthors())
    useEffect(() => {
        fetchAllAuthors().then((authors) => {
            setItems(authors)
        })
    }, [])
    if (!items) {
        return null
    }
    return (
        <div className='authors-vertical'>
            <div className='title'>{t('top_autors')}</div>
            <div className="authors">
                {items && items.slice(0,9).map((item) => <Link key={item.id} to={appSubDir + 'authors/' + item.id}>
                    <img src={item.image ? baseUrl + `storage/uploads/${item.image}.webp` : baseUrl + `images/default.png`} alt='' />
                    <div>{item.name}</div>
                </Link>)}
            </div>
        </div>
    );
};

export default AuthorSwiperVerticle;
