import { fetchProducts,getProducts } from '../../services/products/products.service';
import { useEffect, useState } from 'react';
import { appSubDir } from '../../configs/app';
import ProductsGrid from '../Products/ProductsGrid';
import { useTranslation } from 'react-i18next';

const params = new URLSearchParams("img=web&items_per_page=12")
const AllProducts: React.FC = () => {
    const {t} = useTranslation()
    const productResponse = getProducts(params)
    const [loading, setLoadling] = useState(false)
    const [items, setItems] = useState(productResponse ? productResponse.data : undefined)
    function loadProducts(page: number) {
        const local = getProducts(params)
        if (local) {
            setItems(local.data)
        } else {
            setLoadling(true)
        }
        fetchProducts(params).then((res) => {
            if (res) {
                setItems(res.data)
            }
        }).finally(() => setLoadling(false))
    }
    useEffect(() => {
        loadProducts(1)
    }, [])
    return <>
        <ProductsGrid title={t('all_books')} products={items} routerLink={appSubDir + "shop"} />
    </>
};

export default AllProducts;
