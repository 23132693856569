import { apiBaseUrl, apiConfig } from "../../configs/app";
import { SimpleProduct } from "../../types/product.type";
import CryptoService from "../crypto.services";
import loginService from "../loginService";
type ApiResponse = {
    current_page: number
    data: SimpleProduct[]
    first_page_url: string
    last_page_url: string
    next_page_url: string
    prev_page_url: string | null
    path: string
    from: number
    last_page: number
    per_page: number
    to: number
    total: number
}
function getKey(id: number) {
    return CryptoService.hash('related-products-' + id)
}
export function getRelatedProducts(id: number) {
    const localStorageKey = getKey(id)
    const localData = window.localStorage.getItem(localStorageKey)
    return (localData ? JSON.parse(CryptoService.decrypt(localData)) as SimpleProduct[] : null)
}
export function fetchRelatedProducts(id: number) {
    const localStorageKey = getKey(id)
    return fetch(apiBaseUrl + `products/${id}/related`).then((res) => res.json())
        .then((res: any) => {
            if (res) {
                window.localStorage.setItem(localStorageKey, CryptoService.encrypt(JSON.stringify(res)))
                return res as SimpleProduct[]
            } else {
                return null
            }
        })
}
