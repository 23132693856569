import { useEffect, useState } from 'react';
import ProductsVerticalBox from './ProductsVerticalBox';
import { fetchRelatedProducts, getRelatedProducts } from '../../services/products/related-products.service';
import ProductsGrid from './ProductsGrid';
import { useTranslation } from 'react-i18next';
type Props = {
    id: number
    grid?: boolean
}
const RelatedProducts: React.FC<Props> = ({ id, grid }) => {
    const { t } = useTranslation()
    const [items, setItems] = useState(getRelatedProducts(id))
    const title = t('similar_products')
    function fetchData() {
        fetchRelatedProducts(id)
            .then(res => {
                if (res) {
                    setItems(res)
                }
            })
    }
    useEffect(() => {
        fetchData()
    }, [id])
    if (!items) {
        return <h1>{title}</h1>
    }
    if (grid) {
        return (
            <ProductsGrid title={title} products={items} />
        );
    } else {
        return (
            <ProductsVerticalBox title={title} products={items} />
        );
    }
};

export default RelatedProducts;
