import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonRow, IonSpinner, IonText } from '@ionic/react';
import Header from '../../components/Header/Header';
import { onScroll } from '../../services/sticky-menu.service';
import Footer from '../../components/Footer';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { appSubDir } from '../../configs/app';
import Pagination from '../../components/Pagination';
import { fetchProduct, getLocalProduct } from '../../services/products/product.service';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper';
import ZoomableImg from '../../components/ZoomableImg';
import { Link } from 'react-router-dom';
import HtmlParser from '../../components/HtmlParser';
import './Product.scss'
// import SubjectProductsGrid from '../../components/Products/SubjectProductsGrid';
import QuickViewAddToCart from '../../components/Products/QuickViewAddToCart';
import NewArrivalComponent from '../../components/ProductDetails/NewArrivalComponent';
import FlashSaleComponent from '../../components/ProductDetails/FlashSaleComponent';
import BestSellingComponent from '../../components/ProductDetails/BestSellingComponent';
import { getProductImgUrl } from '../../functions/product.functions';
import SubjectProductsGrid from '../../components/Products/SubjectProductsGrid';
import RelatedProducts from '../../components/Products/RelatedProducts';
import { useTranslation } from 'react-i18next';
type CustomParam = {
    ID: string
}
const Product: React.FC = () => {
    const {t} = useTranslation()
    const { ID } = useParams() as CustomParam
    const contentRef = useRef<HTMLIonContentElement>(null);
    const localProduct = getLocalProduct(parseInt(ID))
    const [item, setItem] = useState(localProduct)
    const [loading, setLoading] = useState(!localProduct)
    const BDT = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'BDT'
    });
    const scrollToTop = () => {
        contentRef.current?.scrollToPoint(0, 0, 1000);
    };
    useEffect(() => {
        if (!localProduct) {
            setLoading(true)
        }
        setItem(getLocalProduct(parseInt(ID)))
        fetchProduct(parseInt(ID)).then(product => {
            if (product) {
                setItem(product)
            }
        }).finally(() => {
            setLoading(false)
        })
        scrollToTop()
    }, [ID])
    function getImages() {
        if (item) {
            let images = [getProductImgUrl(item.image)] as string[]
            if (!!item.gallery_1) {
                images.push(getProductImgUrl(item.gallery_1))
            }
            if (!!item.gallery_2) {
                images.push(getProductImgUrl(item.gallery_2))
            }
            return images

        } else {
            return [] as string[]
        }
    }
    return (
        <IonContent ref={contentRef} fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='ion-padding'>
                    <div className='page-container'>
                        {loading && <div className='box-center'><IonSpinner /></div>}
                        {!loading && !item && <div className='box-center'><IonText><h1>Something Went Wrong</h1></IonText></div>}
                        {!loading && item && <div id="single-product">
                            <IonRow>
                                <IonCol sizeMd='12' sizeLg='9'>
                                    <IonCard>
                                        <IonCardContent>
                                            <div className='row'>
                                                <div className='col gallary'>
                                                    <Swiper
                                                        autoplay={{
                                                            delay: 6000
                                                        }}
                                                        zoom={true}
                                                        pagination={{
                                                            clickable: true,
                                                        }}
                                                        navigation={true}
                                                        modules={[Autoplay, Pagination, Navigation]}>
                                                        {getImages().map((url, index) => <SwiperSlide key={index}>
                                                            <ZoomableImg src={url} />
                                                        </SwiperSlide>)}
                                                    </Swiper>
                                                </div>
                                                <div className='col meta'>
                                                    <h1 className='title'>{item.name}</h1>
                                                    <div className='pricing'>
                                                        <IonText className='sale' color='primary'>{BDT.format(item.sale_price)}</IonText>
                                                        <IonText className='regular'>{BDT.format(item.regular_price)}</IonText>
                                                    </div>
                                                    <QuickViewAddToCart item={item} />
                                                    <IonRow>
                                                        <IonCol size='4'>
                                                            <div className='attribute'>
                                                                <h2>{t('category')}</h2>
                                                                {item.category && <Link to={appSubDir + "categories/" + item.category.id}>{item.category.title}</Link>}
                                                            </div>
                                                        </IonCol>
                                                        <IonCol size='4'>
                                                            <div className='attribute'>
                                                                <h2>{t('subject')}</h2>
                                                                {item.category && item.subject && <>
                                                                    <Link to={appSubDir + "categories/" + item.category.id + "/subjects/" + item.subject.id}>
                                                                        {item.subject.title}</Link>
                                                                </>}
                                                            </div>
                                                        </IonCol>
                                                        <IonCol size='4'>
                                                            <div className='attribute'>
                                                                <h2>{t('author')}</h2>
                                                                {item.author && <Link to={appSubDir + "authors/" + item.author.id}>{item.author.name}</Link>}
                                                            </div>
                                                        </IonCol>
                                                        {item.publisher && <IonCol size='4'>
                                                            <div className='attribute'>
                                                                <h2>{t('publisher')}</h2>
                                                                <p>{item.publisher}</p>
                                                            </div>
                                                        </IonCol>}
                                                        {item.edition && <IonCol size='4'>
                                                            <div className='attribute'>
                                                                <h2>{t('edition')}</h2>
                                                                <p>{item.edition}</p>
                                                            </div>
                                                        </IonCol>}
                                                        {item.language && <IonCol size='4'>
                                                            <div className='attribute'>
                                                                <h2>{t('language')}</h2>
                                                                <p>{item.language}</p>
                                                            </div>
                                                        </IonCol>}
                                                    </IonRow>
                                                </div>
                                            </div>
                                        </IonCardContent>
                                    </IonCard>

                                    <IonCard>
                                        <IonCardHeader>
                                            <IonCardTitle>Description</IonCardTitle>
                                        </IonCardHeader>
                                        <IonCardContent >
                                            <HtmlParser html={item.description} />
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>


                                <IonCol sizeMd='12' sizeLg='3'>
                                    {item.category && <IonCard>
                                        <IonCardContent style={{ padding: 0 }}>
                                            <RelatedProducts id={item.id}/>
                                        </IonCardContent>
                                    </IonCard>}
                                </IonCol>
                            </IonRow>
                        </div>}
                    </div>
                    {!loading && item && <IonCard>
                        <IonCardContent style={{ padding: 0 }}>
                            <SubjectProductsGrid product={item} />
                        </IonCardContent>
                    </IonCard>}
                    <IonCard>
                        <IonCardContent style={{ padding: 0 }}>
                            <FlashSaleComponent />
                        </IonCardContent>
                    </IonCard>
                    <IonCard>
                        <IonCardContent style={{ padding: 0 }}>
                            <NewArrivalComponent />
                        </IonCardContent>
                    </IonCard>
                    <IonCard>
                        <IonCardContent style={{ padding: 0 }}>
                            <BestSellingComponent />
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default Product;
