import { IonItem, IonText } from '@ionic/react';
import { SimpleProduct } from '../../types/product.type';
import './ProductListItem.scss'
import { useRef } from 'react';
import { getProductImgUrl, getProductUrl } from '../../functions/product.functions';
type Props = {
    product: SimpleProduct
}
const ProductListItem: React.FC<Props> = ({ product }) => {
    const modal = useRef<HTMLIonModalElement>(null)
    return <IonItem routerLink={getProductUrl(product.id,product.name)} className='product' onClick={() => modal.current?.present()}>
        <img src={getProductImgUrl(product.image)} slot='start' alt="" />
        <div className='name'>
            <IonText>{product.name}</IonText>
        </div>
        <div className='pricing'>
            <IonText className='regular'>{product.regular_price} </IonText>
            <IonText className='sale' color='primary'>{product.sale_price}/=</IonText>
        </div>
    </IonItem>
};

export default ProductListItem;
