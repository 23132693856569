import { IonButton, IonCol, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonModal, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import Header from '../../components/Header/Header';
import { onScroll } from '../../services/sticky-menu.service';
import Footer from '../../components/Footer';
import { useEffect, useRef, useState } from 'react';
import { fetchCategories, getCategory, getSubject, localCategories } from '../../services/products/all-category.service';
import { useParams } from 'react-router';
import { appSubDir } from '../../configs/app';
import ProductsGrid from '../../components/Products/ProductsGrid';
import Pagination from '../../components/Pagination';
import { fetchSubjectProducts, getSubjectProducts } from '../../services/products/subject-wise-products.service';
import { useTranslation } from 'react-i18next';
import { arrowUp } from 'ionicons/icons';

type CustomParam = {
    catID: string
    subjectID: string
}
const Subject: React.FC = () => {
    const {t} = useTranslation()
    const { catID, subjectID } = useParams() as CustomParam
    const categories = localCategories()
    const productResponse = getSubjectProducts(parseInt(subjectID))
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState(0)
    const [loading, setLoadling] = useState(false)
    const [category, setCategory] = useState(getCategory(categories, parseInt(catID)))
    const [subject, setSubject] = useState(category ? getSubject(category.subjects, parseInt(subjectID)) : null)
    const [products, setProducts] = useState(productResponse ? productResponse.data : null)
    const modal = useRef<HTMLIonModalElement>(null)
    function loadProducts(page: number) {
        const local = getSubjectProducts(parseInt(subjectID), page)
        if (local) {
            setProducts(local.data)
        } else {
            setLoadling(true)
        }
        fetchSubjectProducts(parseInt(subjectID), page).then((res) => {
            if (res) {
                // if (res.current_page === 1 || !products) {
                //     setProducts(res.data)
                //     setLastPage(res.last_page)
                // } else {
                //     setProducts([...products, ...res.data])
                // }
                setProducts(res.data)
                setLastPage(res.last_page)
                setPage(res.current_page)
            }
        }).finally(() => setLoadling(false))
    }
    useEffect(() => {
        const categories = localCategories()
        const category = getCategory(categories, parseInt(catID))
        setCategory(category)
        setSubject(category ? getSubject(category.subjects, parseInt(subjectID)) : null)
        if (categories.length < 1) {
            fetchCategories().then((products) => {
                setCategory(getCategory(products, parseInt(catID)))
            })
        }
        loadProducts(1)
    }, [catID, subjectID])
    return (
        <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='ion-padding bg-content'>
                    <div className='page-container'>
                        {!category && !loading && <div className='text-center'>
                            <h1 style={{ marginTop: 100, marginBottom: 100 }}>Sorry ! Nothing Found</h1>
                            <IonButton routerLink={appSubDir} fill='outline'>
                                Go To Home
                            </IonButton>
                        </div>}
                        {category && <div className='page-container'>
                            <div className='title-bar'>
                                <h6>{t('subject')}</h6>
                                <h1>{subject?.title}</h1>
                                {category && <IonButton fill='outline' routerLink={appSubDir+"categories/"+catID}><IonIcon icon={arrowUp} slot='start'/>{category.title}</IonButton>}
                                {category.subjects.length > 0 && <IonButton onClick={() => modal.current?.present()} fill='outline'>All Other Subjects</IonButton>}
                            </div>
                            <div style={{ marginTop: 30 }}>
                                {products && <ProductsGrid title='Products' products={products} hideHeader={true} />}
                                {lastPage > 1 && <Pagination active={page} disabled={loading} onClick={loadProducts} buttonCount={lastPage} />}
                            </div>
                            {category.subjects.length > 0 && <IonModal ref={modal}>
                                <IonHeader>
                                    <IonToolbar>
                                        <IonTitle>Other Sub Categories</IonTitle>
                                        {window.screen.width > 425 && <IonButton onClick={() => modal.current?.dismiss()} slot='end' fill='clear'>Close</IonButton>}
                                    </IonToolbar>
                                </IonHeader>
                                <IonContent>
                                    {category.subjects.filter((item) => (item.id !== parseInt(subjectID))).map((category, index) => <IonItem key={index} routerLink={appSubDir + "categories/" + category.category_id + "/subjects/" + category.id} onClick={() => modal.current?.dismiss()}>{category.title}</IonItem>)}
                                </IonContent>
                                {window.screen.width <= 425 && <IonFooter>
                                    <IonButton onClick={() => modal.current?.dismiss()} expand='block' fill='clear'>Close</IonButton>
                                </IonFooter>}
                            </IonModal>}
                        </div>}
                    </div>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default Subject;
