import { IonCol, IonContent, IonRow, useIonViewDidEnter } from '@ionic/react';
import Header from '../components/Header/Header';
import { onScroll } from '../services/sticky-menu.service';
import Footer from '../components/Footer';
import './Contact.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const Contact: React.FC = () => {
    const {t} = useTranslation()
    useIonViewDidEnter(() => {
        const tabBar = document.querySelector('ion-tab-bar');
        if (tabBar !== null) {
            tabBar.style.display = 'none';
        }
    })
    return (
        <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content' id="contact-page">
                    <div className="banner">
                        <img src="assets/img/contact-us-banner.webp" alt="" />
                        <div className='title'>
                            <h1>{t('contact_us')}</h1>
                            <hr />
                            <p>We provide instant support. <br /> Just one email or phone call away</p>
                        </div>
                    </div>
                    <IonRow>
                        <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='6'>
                            <div className="box text-end">
                                <h1>Location</h1>
                                <hr />
                                <p>51/51 A <br />Resourcefull Paltan City <br />(level-5),
                                    (level-5,suite-602) <br />Purana Paltan Dhaka-1000 </p>
                            </div>
                        </IonCol>
                        <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='6'>
                            <div className="box">
                                <h1>Contact</h1>
                                <hr />
                                <p><a href="tel:+8801689636569" title="Click To Call" ><FontAwesomeIcon icon={faPhone} /> +8801689636569</a>, <a href="tel:+8801676457427" title="Click To Call"><FontAwesomeIcon icon={faPhone} /> +8801676457427</a></p>
                                <p><a title="Company Email (Click To Send Email)" href="mailto:bbazzarbd@gmail.com"><FontAwesomeIcon icon={faEnvelope} /> bbazzarbd@gmail.com</a></p>
                                <p><a title="Information Email (Click To Send Email)" href="mailto:info.bbazzarbd@gmail.com"><FontAwesomeIcon icon={faEnvelope} /> info.bbazzarbd@gmail.com</a></p>
                            </div>
                        </IonCol>
                    </IonRow>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3652.451548367911!2d90.41217!3d23.731272!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b8f623cf3611%3A0x1b15bfe121b648d7!2sResourceful%20Paltan%20City%2C%20Dhaka.!5e0!3m2!1sen!2sbd!4v1690861308540!5m2!1sen!2sbd" width="100%" height="450" loading="lazy"></iframe>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default Contact;
