
import React, { useEffect } from 'react';
import {
    IonItem, IonGrid, IonRow, IonCol,
    IonIcon,
    IonLabel,
    IonButton
} from '@ionic/react';
import { closeCircleOutline, cloudUploadOutline } from 'ionicons/icons';
import { usePrintContext } from '../../contexts/print.context';
import { getPageCount, uploadPdf } from '../../services/print.service';
import { Printable } from '../../types/shop/cart.type';





const UploadSection: React.FC = () => {
    const { files, setFiles } = usePrintContext()
    const handleUploadClick = () => {
        document.getElementById('pdfInput')?.click();
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const newFiles: Printable[] = []
            const promises = Array.from(event.target.files).map(async (file) => {
                const fileUploaded = await uploadPdf(file)
                if (!fileUploaded) return
                const temp_file = fileUploaded.data as string
                const page_count = await getPageCount(file)
                if (page_count < 1) return
                const { name, size } = file
                newFiles.push({
                    name, size, temp_file, page_count
                })
            })
            Promise.all(promises).then(() => {
                // console.log({ newFiles })
                // console.log({ files })
                setFiles([...files, ...newFiles]);
                event.target.value = ""
            })
        }
    };

    function handleRemoveClick(index: number): void {
        files.splice(index, 1)
        setFiles([...files]);
    }

    return (
        <IonGrid>
            <IonRow>
                <IonCol size={files.length > 0 ? "6" : "12"}>
                    <IonItem>
                        <div className='uploader' onClick={handleUploadClick} style={{ cursor: 'pointer' }}>
                            <IonIcon icon={cloudUploadOutline} color='tertiary' className='icon' />
                            <h3>Click here to upload your pdfs</h3>
                            <p> which you want to order for printing.</p>
                            <p>Upload pdfs not larger than 200MB.</p>
                            <input
                                type="file"
                                multiple
                                id="pdfInput"
                                accept=".pdf"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </div>
                    </IonItem>
                </IonCol>
                {files.length > 0 && (
                    <IonCol size="6">
                        <IonItem color='light'>
                            <h3>Selected files:</h3>
                        </IonItem>
                        {files.map((printable, index) => (
                            <IonItem key={index}>
                                <IonLabel>
                                    <h3>{printable.name}</h3>
                                    <p>{(printable.size / 1048576).toFixed(2)} MB - {printable.page_count} Pages</p>
                                </IonLabel>
                                <IonButton fill='clear' size='large' color='danger' onClick={() => handleRemoveClick(index)}><IonIcon icon={closeCircleOutline} /></IonButton>
                            </IonItem>
                        ))}
                    </IonCol>
                )}
            </IonRow>
        </IonGrid>
    );
}


export default UploadSection;
