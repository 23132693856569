// frontend/src/pages/ComparePage.tsx

import React, { useEffect, useState } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButton,
    IonSpinner,
    IonText,
    IonImg,
} from '@ionic/react';
import Product from '../types/product.type';
import {
    clearCompareProductIds,
    getCompareProductIds,
    removeCompareProductId,
} from '../services/products/compare.service';
import { getLocalProduct, fetchProduct } from '../services/products/product.service';
import FullPageLayout from '../components/layouts/FullPageLayout';
import { appSubDir } from '../configs/app';
import { getProductImgUrl, getProductUrl } from '../functions/product.functions';
import './ComparePage.scss'; // Import the SCSS file

const ComparePage: React.FC = () => {
    const location = useLocation();
    const history = useHistory();
    const [products, setProducts] = useState<Product[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    /**
     * Define the features to compare
     */
    const features = [
        { label: 'Name', key: 'name' },
        {
            label: 'Category', key: 'category', render: (product: Product) => <Link
                to={appSubDir + "categories/" + product.category?.id}>
                {product.category?.title}
            </Link>
        },
        {
            label: 'Subject', key: 'subject', render: (product: Product) => <Link
                to={appSubDir + "categories/" + product.category?.id + "/subjects/" + product?.subject.id}>
                {product?.subject.title}
            </Link>
        },
        { label: 'Author', key: 'autor', render: (product: Product) => <Link to={appSubDir + "authors/" + product.author?.id}>{product.author?.name}</Link> },
        { label: 'Regular Price', key: 'regular_price', render: (product: Product) => `${product.regular_price.toFixed(2)} /=` },
        { label: 'Sale Price', key: 'sale_price', render: (product: Product) => `${product.sale_price.toFixed(2)} /=` },
        { label: 'Publisher', key: 'publisher' },
        { label: 'Edition', key: 'edition' },
        { label: 'Language', key: 'language' }
        // Add more features as needed
    ];

    /**
     * Parse query parameters to get product IDs
     */
    const parseProductIds = (): number[] => {
        const params = new URLSearchParams(location.search);
        const idsParam = params.get('ids');
        if (idsParam) {
            return idsParam
                .split(',')
                .map(id => parseInt(id, 10))
                .filter(id => !isNaN(id));
        }
        return [];
    };

    /**
     * Fetch product data based on IDs
     */
    const loadProducts = async (ids: number[]) => {
        setLoading(true);
        const fetchedProducts: Product[] = [];

        for (const id of ids) {
            let product = getLocalProduct(id);
            if (!product) {
                product = await fetchProduct(id);
            }
            if (product) {
                fetchedProducts.push(product);
            }
        }

        setProducts(fetchedProducts);
        setLoading(false);
    };

    useEffect(() => {
        const ids = parseProductIds();
        if (ids.length === 0) {
            setLoading(false);
            return;
        }
        loadProducts(ids);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    /**
     * Handle removing a product from comparison
     */
    const handleRemove = (id: number) => {
        removeCompareProductId(id);
        const updatedIds = getCompareProductIds();
        history.replace(`/compare?ids=${updatedIds.join(',')}`);
        setProducts(products.filter(product => product.id !== id));
    };

    /**
     * Handle clearing all comparisons
     */
    const handleClearAll = () => {
        clearCompareProductIds();
        history.replace('/compare');
        setProducts([]);
    };

    if (loading) {
        return (
            <FullPageLayout>
                <div id="compare-page" className="compare-loading">
                    <IonSpinner name="crescent" />
                </div>
            </FullPageLayout>
        );
    }

    if (products.length === 0) {
        return (
            <FullPageLayout>
                <div id="compare-page" className="compare-no-products">
                    <h1>Compare Products</h1>
                    <p>No products selected for comparison.</p>
                    <IonButton routerLink={appSubDir} fill="outline">
                        Go To Home
                    </IonButton>
                </div>
            </FullPageLayout>
        );
    }

    return (
        <FullPageLayout>
            <div id="compare-page">
                <div className="compare-header">
                    <h1>Compare Products</h1>
                    <IonButton onClick={handleClearAll} color="danger">
                        Clear All
                    </IonButton>
                </div>
                <div className="table-responsive">
                    <table className="compare-table">
                        <thead>
                            <tr>
                                <th>Feature</th>
                                {products.map(product => (
                                    <th key={product.id}>
                                        <Link to={getProductUrl(product.id, product.slug)}>
                                            <IonImg src={getProductImgUrl(product.image)} alt={product.name} className="compare-product-image" />
                                        </Link>
                                        <IonButton size="small" fill='outline' color="danger" onClick={() => handleRemove(product.id)}>
                                            Remove
                                        </IonButton>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {features.map(feature => {
                                return (
                                    <tr key={feature.key}>
                                        <td><strong>{feature.label}</strong></td>
                                        {products.map(product => (
                                            <td
                                                key={product.id}
                                            >
                                                {feature.render ? feature.render(product) : (product as any)[feature.key]}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </FullPageLayout>
    );
};

export default ComparePage;
