import CryptoService from "../crypto.services";

const COMPARE_KEY = CryptoService.hash('compareProductIds')

/**
 * Get the list of product IDs selected for comparison from local storage
 */
export const getCompareProductIds = (): number[] => {
    const storedIds = window.localStorage.getItem(COMPARE_KEY);
    return storedIds ? JSON.parse(storedIds) as number[] : [];
};

/**
 * Add a product ID to the comparison list
 * @param productId - ID of the product to add
 * @param limit - Maximum number of products to compare (default: 4)
 */
export const addCompareProductId = (productId: number, limit: number = 4): boolean => {
    const ids = getCompareProductIds();
    if (!ids.includes(productId)) {
        if (ids.length >= limit) {
            return false; // Indicate that the limit has been reached
        }
        ids.push(productId);
        window.localStorage.setItem(COMPARE_KEY, JSON.stringify(ids));
        return true;
    }
    return true; // Already in the list
};

/**
 * Remove a product ID from the comparison list
 * @param productId - ID of the product to remove
 */
export const removeCompareProductId = (productId: number): void => {
    let ids = getCompareProductIds();
    ids = ids.filter(id => id !== productId);
    window.localStorage.setItem(COMPARE_KEY, JSON.stringify(ids));
};

/**
 * Clear all product IDs from the comparison list
 */
export const clearCompareProductIds = (): void => {
    window.localStorage.removeItem(COMPARE_KEY);
};
