import React, { createContext, useContext, useEffect, useState } from 'react';
import PrintingCost from '../types/printing-cost';
import axios from 'axios';
import { appSubDir } from '../configs/app';
import { Printable } from '../types/shop/cart.type';

interface ShippingDetails {
    deliveryType: number
    name: string
    mobile: string
    email: string
    address: string
}

interface PrintContextData {
    files: Printable[]
    totalPageCount: number
    pageSize: number
    paperQuality: number
    coverType: number
    cost: PrintingCost
    shipptingDetails: ShippingDetails
    setFiles: React.Dispatch<React.SetStateAction<Printable[]>>
    setCost: React.Dispatch<React.SetStateAction<PrintingCost>>
    setPageSize: React.Dispatch<React.SetStateAction<number>>
    setPaperQuality: React.Dispatch<React.SetStateAction<number>>
    setCoverType: React.Dispatch<React.SetStateAction<number>>
    setShippingDetails: React.Dispatch<React.SetStateAction<ShippingDetails>>
}

export const defaultState = {
    files: [],
    totalPageCount: 0,
    cost: {
        paper_sizes: [
            {
                label: "Half A4/A5 (Black & White)",
                page_qualities: [
                    {
                        label: "70 GSM - Offset Paper",
                        price_per_page: 2
                    },
                    {
                        label: "80 GSM - Offset Paper",
                        price_per_page: 2.5
                    }
                ]
            },
            {
                label: "Half A4/A5 (Colour)",
                page_qualities: [
                    {
                        label: "70 GSM - Offset Paper",
                        price_per_page: 3
                    },
                    {
                        label: "80 GSM - Offset Paper",
                        price_per_page: 3.5
                    }
                ]
            },
            {
                label: "A4/B5 (Black & White)",
                page_qualities: [
                    {
                        label: "70 GSM - Offset Paper",
                        price_per_page: 4
                    },
                    {
                        label: "80 GSM - Offset Paper",
                        price_per_page: 4.5
                    }
                ]
            },
            {
                label: "A4/B5 (Color)",
                page_qualities: [
                    {
                        label: "70 GSM - Offset Paper",
                        price_per_page: 5
                    },
                    {
                        label: "80 GSM - Offset Paper",
                        price_per_page: 5.5
                    }
                ]
            },
            {
                label: "A4/A5/B5 Karnaphuli (Black & White)",
                page_qualities: [
                    {
                        label: "70 GSM - Offset Paper",
                        price_per_page: 6
                    },
                    {
                        label: "80 GSM - Offset Paper",
                        price_per_page: 6.5
                    }
                ]
            }
        ],
        cover: [
            {
                label: "Paper Back",
                price: 50
            },
            {
                label: "Thesis Cover",
                price: 70
            },
            {
                label: "Hard Cover",
                price: 80
            }
        ],
        deliberyCosts: [
            {
                label: "Inside Dhaka",
                cost: 100
            },
            {
                label: "Outside Data",
                cost: 200
            }
        ]
    },
    shipptingDetails: {
        deliveryType: -1,
        name: '',
        mobile: '',
        email: '',
        address: ''
    },
    pageSize: -1,
    coverType: -1,
    paperQuality: -1,
    setFiles: () => { },
    setTotalPageCount: () => { },
    setCost: () => { },
    setPageSize: () => { },
    setPaperQuality: () => { },
    setCoverType: () => { },
    setShippingDetails: () => { }
} as PrintContextData;

export const PrintContext = createContext(defaultState);

type Props = {
    children: JSX.Element[] | JSX.Element
}
export const PrintContextProvider: React.FC<Props> = ({ children }) => {
    const [files, setFiles] = useState<Printable[]>(defaultState.files);
    const [pageSize, setPageSize] = useState<number>(defaultState.pageSize);
    const [paperQuality, setPaperQuality] = useState<number>(defaultState.paperQuality);
    const [coverType, setCoverType] = useState<number>(defaultState.coverType)
    const [cost, setCost] = useState<PrintingCost>(defaultState.cost)
    const [shipptingDetails, setShippingDetails] = useState<ShippingDetails>(defaultState.shipptingDetails)
    const totalPageCount = files.reduce((prev, current) => (prev + current.page_count), 0)
    useEffect(() => {
        axios.get(appSubDir + "print-cost.json").then((res) => {
            setCost(res.data);
        })
    }, [])
    return <PrintContext.Provider value={{
        files, setFiles,
        totalPageCount,
        cost, setCost,
        pageSize, setPageSize,
        paperQuality, setPaperQuality,
        coverType, setCoverType,
        shipptingDetails, setShippingDetails
    }}>
        {children}
    </PrintContext.Provider>
};
export const usePrintContext = () => {
    return useContext(PrintContext);
}
