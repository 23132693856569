import { useEffect, useState } from 'react';
import { fetchProducts,getProducts } from '../../services/products/products.service';
import ProductsGrid from '../Products/ProductsGrid';
import { appSubDir } from '../../configs/app';

const params = new URLSearchParams("flush_product=flush_product&random_order=true&img=web&items_per_page=12")
const FlashSaleComponent: React.FC = () => {
    const localProduct = getProducts(params)
    const [items, setItems] = useState(localProduct?.data)
    useEffect(() => {
        fetchProducts(params).then(product => {
            if (product) {
                setItems(product.data)
            }
        })
    }, [])
    if(!items){
        return null
    }
    return (
        <ProductsGrid title='Top Deals' products={items} routerLink={appSubDir+"flash-sale"}/>
    );
};

export default FlashSaleComponent;
