
import React from 'react';
import {
    IonImg, IonGrid, IonRow, IonCol
} from '@ionic/react';
import { appSubDir } from '../../configs/app';
import { usePrintContext } from '../../contexts/print.context';

const PagesSection: React.FC = () => {

    const { totalPageCount } = usePrintContext()
    if(totalPageCount < 1){
        return null
    }
    return (
        <IonGrid>
            <IonRow>
                <IonCol size="6">
                    <IonImg src={appSubDir + "assets/img/printing.svg"} alt="Print Cost" />
                </IonCol>
                <IonCol size="6">
                    <div className='page-count'>
                        <h3>Total number of pages</h3>
                        <p>Your PDF is scanned automatically. Total number of pages</p>
                        <h1 id="pageCount">{totalPageCount}</h1>
                    </div>
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}
export default PagesSection;
