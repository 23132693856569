import {
    IonButton, IonCheckbox, IonContent, IonFooter, IonHeader, IonInput, IonItem, IonLabel,
    IonNote,
    IonRadio, IonRadioGroup, IonSelect, IonSelectOption, IonText, IonTextarea, IonTitle, IonToolbar, useIonToast
} from '@ionic/react';


import { useAppContext, useAppContextUpdater } from '../../services/app-context.service';
import './CheckoutBody.scss'
import { useEffect, useRef, useState } from 'react';
import { fetchShippingCosts, getShippingCosts } from '../../services/shop/shipping-costs.service';
import { Formik, FormikHelpers } from 'formik';
import shoppingCartService from '../../services/shop/cart.service';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import sendOTPrequest from '../../functions/otp.functions';
import CheckoutOtp from './CheckoutOtp';
import { checkoutModalRef } from './CartModal';
import { appSubDir, recaptchaSiteKey } from '../../configs/app';
import { useHistory } from 'react-router';
import CartTotal from './CartTotal';
import GoogleReCaptcha from '../GoogleReCaptcha';
type FormFields = {
    name: string
    phone: string
    shippingCostId: string
    address: string
    note: string
    saveShippingDetails: boolean
    paymentMethod: string
    paidUnpaid: string
}
const BDT = new Intl.NumberFormat('en-IN');
const CheckoutBody: React.FC = () => {
    const history = useHistory()
    const appData = useAppContext()
    const setAppData = useAppContextUpdater()
    const { user, cart } = appData
    const [shippingCosts, setShippingCosts] = useState(getShippingCosts())
    const submitBtnRef = useRef<HTMLInputElement>(null)
    const checkboxRef = useRef<HTMLInputElement>(null)
    const [reCaptchaToken, setReCaptchaToken] = useState<string | null>(null)
    const [otpPayload, setOtpPayload] = useState<string | null>(null)
    const [isFormSubmitting, setIsFormSubmitting] = useState(false)
    const isReCaptchRequired = !user.token && !reCaptchaToken
    const [presentToast] = useIonToast();
    useEffect(() => {
        fetchShippingCosts().then((items) => setShippingCosts(items))
    }, [])
    function validator(values: FormFields) {
        const errors = {} as FormFields;
        if (!values.name) {
            errors.name = 'Required';
        } else if (
            values.name.includes("@")
        ) {
            errors.name = 'Invalid Name address';
        }

        if (!values.phone) {
            errors.phone = 'Required';
        } else if (
            values.phone.length != 11
        ) {
            errors.phone = '11 Digit Required';
        } else if (
            !values.phone.match(/^(01){1}[3-9]{1}\d{8}/g)
        ) {
            errors.phone = 'Invalid Phone Number';
        }

        if (!values.address) {
            errors.address = 'Required';
        }

        if (!values.shippingCostId) {
            errors.shippingCostId = 'Selct A Shipping Cost';
        }

        if (user.token && !values.paymentMethod) {
            errors.paymentMethod = 'Select A Payment Method';
        }
        if (user.role_id == 2 && !values.paidUnpaid) {
            errors.paidUnpaid = 'Required';
        }
        updateCart(values)
        return errors;
    }
    function updateCart(values: FormFields) {
        let cartUpdated = false
        if (values.paidUnpaid && shoppingCartService.shoppingCart.paidUnpaid != values.paidUnpaid) {
            shoppingCartService.shoppingCart.paidUnpaid = values.paidUnpaid as "Unpaid" | "Paid"
            cartUpdated = true
        }
        if (values.paymentMethod && shoppingCartService.shoppingCart.paymentType != values.paymentMethod) {
            shoppingCartService.shoppingCart.paymentType = values.paymentMethod
            cartUpdated = true
        }
        const shippingCost = shippingCosts.find((item) => parseInt(values.shippingCostId) === item.id)
        if (shippingCost) {
            shoppingCartService.shoppingCart.totalDeliveryCharge = shippingCost.cost
            shoppingCartService.shoppingCart.shippingCost = shippingCost
            cartUpdated = true
        }
        if (cartUpdated) {
            shoppingCartService.saveCart()
            appData.cart = shoppingCartService.shoppingCart
            setAppData({ ...appData })
        }
    }
    function saveCart(values: FormFields) {
        // console.log(values);

        // console.log("paymentMethod", values.paymentMethod)
        // reCaptchaRef.current?.reset()
        shoppingCartService.shoppingCart.shippingAddress.name = values.name
        shoppingCartService.shoppingCart.shippingAddress.phone = values.phone
        shoppingCartService.shoppingCart.shippingAddress.address = values.address
        shoppingCartService.shoppingCart.shippingAddress.note = values.note
        shoppingCartService.shoppingCart.saveShippingDetails = values.saveShippingDetails
        shoppingCartService.shoppingCart.paymentType = values.paymentMethod
        if (user.role_id == 2 && values.paidUnpaid) {
            shoppingCartService.shoppingCart.paidUnpaid = values.paidUnpaid as "Unpaid" | "Paid"
        } else {
            shoppingCartService.shoppingCart.paidUnpaid = 'Unpaid'
        }
        const shippingCost = shippingCosts.find((item) => parseInt(values.shippingCostId) === item.id)
        if (shippingCost) {
            shoppingCartService.shoppingCart.totalDeliveryCharge = shippingCost.cost
            shoppingCartService.shoppingCart.shippingCost = shippingCost
        }
        shoppingCartService.saveCart()
    }
    function placeLoggedInUserOrder(values: FormFields, formikHelpers: FormikHelpers<FormFields>) {
        const { setSubmitting } = formikHelpers

        saveCart(values)
        setSubmitting(true);
        setIsFormSubmitting(true)
        shoppingCartService.makeOrder().then((response) => {
            if (response.data.invoice_id) {
                shoppingCartService.clearCart()
                setAppData({ ...appData, cart: shoppingCartService.shoppingCart })
                checkoutModalRef.current?.dismiss()
                presentToast({
                    message: "Order Placed Successfully.",
                    duration: 3000,
                    cssClass: 'custom-toast',
                    animated: true,
                    color: "success",
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ],
                })
                history.replace(appSubDir + "invoices/" + response.data.invoice_id)
            } else if (response.data.gatewayPageURL) {
                window.location.href = response.data.gatewayPageURL
            }
        }).catch((error) => {
            presentToast({
                message: error.response.data.error ? error.response.data.error : "Something Went Wrong.",
                duration: 3000,
                cssClass: 'custom-toast',
                animated: true,
                color: "danger",
                buttons: [
                    {
                        text: 'Dismiss',
                        role: 'cancel'
                    }
                ],
            })
        }).finally(() => {
            setSubmitting(false);
            setIsFormSubmitting(false)
        })
    }
    function placeGueseUserOrder(values: FormFields, formikHelpers: FormikHelpers<FormFields>) {
        if (!reCaptchaToken) {
            console.error("ReCaptcha Token Was not set")
            return
        }
        const { setSubmitting } = formikHelpers

        saveCart(values)
        setSubmitting(true);
        setIsFormSubmitting(true)
        // sendOTPrequest(values.phone, reCaptchaToken).then((payload) => {
        //     setOtpPayload(payload)
        // })
        shoppingCartService.placeGuestOrder("", "").then((response) => {
            if (response.invoice_id) {
                shoppingCartService.clearCart()
                setAppData({ ...appData, cart: shoppingCartService.shoppingCart })
                setTimeout(()=>{
                    history.replace(appSubDir + "order-placed" ,{invoice_id:response.invoice_id})
                },100)

                presentToast({
                    message: "We have Received Your Order.",
                    duration: 3000,
                    cssClass: 'custom-toast',
                    animated: true,
                    color: "success",
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ],
                })
            } else if (response.gatewayPageURL) {
                window.location.href = response.gatewayPageURL
            }
        }).catch(errPromise => {
            errPromise.then((errResponse: any) => {
                // console.error(errResponse)
                presentToast({
                    message: errResponse.message ? errResponse.message : "Something Went Wrong",
                    duration: 3000,
                    cssClass: 'custom-toast',
                    animated: true,
                    color: "danger",
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ],
                })
            })
        })
            .finally(() => {
                setSubmitting(false);
                setIsFormSubmitting(false)
                setReCaptchaToken(null)
            })
    }
    function onSubmit(values: FormFields, formikHelpers: FormikHelpers<FormFields>) {

        if (user.token) {
            placeLoggedInUserOrder(values, formikHelpers)
        } else {
            placeGueseUserOrder(values, formikHelpers)
        }
    }
    // if (otpPayload) {
    //     return <CheckoutOtp
    //         payload={otpPayload}
    //         phone={shoppingCartService.shoppingCart.shippingAddress.phone}
    //         onBack={() => { setOtpPayload(null) }}
    //         onNext={() => { }}
    //     />
    // }
    return (
        <>
            <IonHeader>
                <IonToolbar color="light">
                    <IonTitle>Shipping Information</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className='ion-padding' id="checkout-body" style={{ overflow: "auto" }}>
                <Formik
                    initialValues={{
                        name: cart.shippingAddress.name,
                        phone: cart.shippingAddress.phone,
                        address: cart.shippingAddress.address,
                        note: cart.shippingAddress.note,
                        shippingCostId: cart.shippingCost ? cart.shippingCost.id : "",
                        saveShippingDetails: !!cart.saveShippingDetails,
                        paymentMethod: 'Cash On Delivery'
                    } as FormFields}
                    validate={validator}
                    onSubmit={onSubmit}>
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                        /* and other goodies */
                    }) => (
                        <form className='colored' onSubmit={handleSubmit}>
                            <IonItem className={errors.name && touched.name ? 'ion-invalid' : undefined}>
                                <IonLabel position="floating">Name</IonLabel>
                                <IonInput name='name'
                                    onIonChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name} />
                                {errors.name && touched.name && <IonNote slot='error'>{errors.name}</IonNote>}
                            </IonItem>
                            <IonItem className={errors.phone && touched.phone ? 'ion-invalid' : undefined}>
                                <IonLabel position="floating">Phone</IonLabel>
                                <IonInput name='phone' placeholder='01XXXXXXXXX (BD Mobile Number Only)' min={1000000000} max={1999999999}
                                    onIonChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.phone} />
                                {errors.phone && touched.phone && <IonNote slot='error'>{errors.phone}</IonNote>}
                            </IonItem>
                            <IonItem className={errors.shippingCostId ? 'ion-invalid' : undefined}>
                                <IonLabel>Shipping Cost</IonLabel>
                                <IonSelect name='shippingCostId'
                                    onIonChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.shippingCostId} interface="action-sheet">
                                    {shippingCosts.map((item, index) => <IonSelectOption key={index} value={item.id}>
                                        {BDT.format(item.cost)} TK - {item.reason}
                                    </IonSelectOption>)}
                                </IonSelect>
                                {errors.shippingCostId && <IonNote slot='error'>{errors.shippingCostId}</IonNote>}
                            </IonItem>

                            <IonItem className={errors.address && touched.address ? 'ion-invalid' : undefined}>
                                <IonLabel position="floating">Address</IonLabel>
                                <IonTextarea name='address'
                                    onIonChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.address} />
                                {errors.address && touched.address && <IonNote slot='error'>{errors.address}</IonNote>}
                            </IonItem>

                            <IonItem className={errors.note && touched.note ? 'ion-invalid' : undefined}>
                                <IonLabel position="floating">Note</IonLabel>
                                <IonTextarea name='note'
                                    onIonChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.note} />
                                {errors.note && touched.note && <IonNote slot='error'>{errors.note}</IonNote>}
                            </IonItem>
                            <IonItem className={errors.saveShippingDetails && touched.saveShippingDetails ? 'ion-invalid' : undefined}>
                                <IonCheckbox slot='end' onIonChange={(e) => checkboxRef.current?.click()} />
                                <IonLabel>
                                    Save Shipping Details
                                    <input style={{ display: 'none' }} slot='end' type='checkbox' name='saveShippingDetails'
                                        onChange={handleChange} ref={checkboxRef} />
                                </IonLabel>
                                {errors.saveShippingDetails && touched.saveShippingDetails && <IonNote slot='error'>{errors.saveShippingDetails}</IonNote>}
                            </IonItem>

                            {user.role_id == 2 && <>
                                <div className="radio-box">
                                    <IonLabel>Shipping Cost {errors.paidUnpaid && touched.paidUnpaid && <IonNote slot='error'>{errors.paidUnpaid}</IonNote>}</IonLabel>
                                    <IonRadioGroup name='paidUnpaid'
                                        onIonChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.paidUnpaid}>
                                        <IonRadio value="Paid" />On Me
                                        <IonRadio value="UnPaid" />On Customer
                                    </IonRadioGroup>
                                </div>

                            </>}
                            {!!user.token && <div className="radio-box">
                                <IonLabel>Payment Method</IonLabel>
                                <IonRadioGroup name='paymentMethod'
                                    onIonChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.paymentMethod}>
                                    <IonRadio value="Cash On Delivery" />Cash On Delivery
                                    <IonRadio value="sslcommerz" />SSLCommerz
                                </IonRadioGroup>
                            </div>}

                            {errors.paymentMethod && <IonText color='danger' style={{ padding: "0 20px" }}>{errors.paymentMethod}</IonText>}
                            <input style={{ display: 'none' }} disabled={isSubmitting} type='submit' ref={submitBtnRef} />
                        </form>
                    )}
                </Formik>
                {isReCaptchRequired && <div style={{ display: "flex", justifyContent: "center", padding: '20px 0 0' }}>
                    <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>
                        <GoogleReCaptcha
                            action='guestUserCheckout'
                            onVerify={setReCaptchaToken}
                        />
                    </GoogleReCaptchaProvider>
                </div>}
            </IonContent>
            {cart.itemCount > 0 && !isReCaptchRequired && <IonFooter>
                <CartTotal />
                <IonButton disabled={isFormSubmitting} onClick={() => {
                    submitBtnRef.current?.click()
                }} color="primary" expand="block">{
                        isFormSubmitting ?
                            "Placing Order...." :
                            (user.token ? (cart.paymentType == "Cash On Delivery" ? "Place Order" : "Proceed to Payment Gateware") : "Place Order")
                    }</IonButton>
            </IonFooter>}

        </>
    );
};

export default CheckoutBody;
