import { IonCol, IonContent, IonRow } from '@ionic/react';
import Header from '../Header/Header';
import Footer from '../Footer';
import { onScroll } from '../../services/sticky-menu.service';
type Props = {
    children: JSX.Element[] | JSX.Element
};
const FullPageLayout: React.FC<Props> = ({ children }) => {
    return <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
        <Header />
        <IonRow>
            <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                {children}
            </IonCol>
        </IonRow>
        <Footer />
    </IonContent>
};

export default FullPageLayout;
