
import React from 'react';
import {
    IonContent, IonRow, IonCol,
    IonCard, IonCardContent
} from '@ionic/react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer';
import './PrintPage.scss'
import { PrintContextProvider } from '../contexts/print.context';
import UploadSection from '../components/PrintPage/UploadSection';
import PagesSection from '../components/PrintPage/PagesSection';
import RequirementsSection from '../components/PrintPage/RequirementsSection';
import CostSection from '../components/PrintPage/CostSection';



const PrintPage: React.FC = () => {

    return (
        <IonContent fullscreen>
            <Header />
            <IonRow id="print-page">
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding'>
                    <IonCard>
                        <IonCardContent>
                            <PrintContextProvider>
                                <UploadSection />
                                <PagesSection />
                                <RequirementsSection />
                                <CostSection />
                                {/* <DeliverySection />
                                <InvoiceSection />
                                <GreetingsSection /> */}
                            </PrintContextProvider>
                        </IonCardContent>

                    </IonCard>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default PrintPage;
