import { IonButton, IonCol, IonGrid, IonIcon, IonItem, IonItemGroup, IonLabel, IonRow, IonText } from '@ionic/react';

import styles from "./CartBody.module.scss";
import CartProduct from './CartProduct';
import { useAppContext, useAppContextUpdater } from '../../services/app-context.service';
import { closeCircleOutline } from 'ionicons/icons';
import shoppingCartService from '../../services/shop/cart.service';
const CartBody: React.FC = () => {
    const data = useAppContext()
    const setData = useAppContextUpdater()
    const { cart } = data
    function handleRemoveClick(index: number): void {
        data.cart = shoppingCartService.removePrintItem(index)
        setData({ ...data });
    }
    return (
        <>
            <IonGrid>
                {cart.itemCount < 1 && cart.printItems.length < 1 &&
                    <IonRow className={styles.emptyCartContainer}>
                        <IonCol size="10" className="ion-text-center">
                            <div className={styles.text}>
                                <img src="/assets/img/cart.webp" alt="no cart" />
                                <h1>Hang on there!</h1>
                                <p>Your cart is empty</p>
                                <IonButton color="primary" routerLink="/shop">Shop now &rarr;</IonButton>
                            </div>
                        </IonCol>
                    </IonRow>
                }
                {cart.itemCount > 0 &&
                    <>
                        <IonRow>
                            {cart.items.map((item, index) => {
                                return <CartProduct itemIndex={index} cartItem={item} key={index} />;
                            })}
                        </IonRow>
                    </>
                }
                {cart.printItems.length > 0 &&
                    <>
                        <IonItemGroup>
                            <IonItem>
                                <IonLabel><b>Printing Orders</b></IonLabel>
                            </IonItem>
                            {cart.printItems.map((item, index) => {
                                return <IonItem key={index}>
                                    <IonLabel>
                                        {item.files.length} Document(s) - {item.pages} Page(s)
                                    </IonLabel>
                                    <IonText>{item.item_total} TK</IonText>
                                    <IonButton fill='clear' size='small' color='danger' onClick={() => handleRemoveClick(index)}><IonIcon icon={closeCircleOutline} /></IonButton>
                                </IonItem>
                            })}
                        </IonItemGroup>
                    </>
                }
            </IonGrid>
        </>
    );
};

export default CartBody;
