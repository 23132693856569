import React from 'react';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { Route } from 'react-router-dom';
import { IonReactRouter } from '@ionic/react-router';
import { privateRoutes, publicRoutes } from '../routes'; // Import the TypeScript route configuration
import NotFound from '../pages/NotFound';
import { useAppContext } from '../services/app-context.service';
import { appSubDir } from '../configs/app';

const AuthGuard: React.FC = () => {
    const { user } = useAppContext();
    const routes = [...publicRoutes]
    if (user.token) {
        routes.push(...privateRoutes)
    }
    return (
        <IonReactRouter>
            <IonRouterOutlet>
                {[...(routes.map(({ path, Component }, index) => <Route
                    key={index}
                    path={appSubDir + path}
                    exact
                    render={(props) => <Component {...props} />} />)),
                <Route key={user.token ? privateRoutes.length + publicRoutes.length : publicRoutes.length}>
                    <NotFound />
                </Route>]}
            </IonRouterOutlet>
        </IonReactRouter>
    );
};

export default AuthGuard;
