
import React from 'react';
import {
    IonImg, IonGrid, IonRow, IonCol,
    IonButton,
    IonText
} from '@ionic/react';
import { appSubDir } from '../../configs/app';
import { usePrintContext } from '../../contexts/print.context';
import { useAppContext, useAppContextUpdater } from '../../services/app-context.service';
import { cartModalRef, checkoutModalRef } from '../Shop/CartModal';
import shoppingCartService from '../../services/shop/cart.service';




const CostSection: React.FC = () => {
    const data = useAppContext()
    const SetData = useAppContextUpdater()
    const { totalPageCount, files, cost, pageSize, paperQuality, coverType, setFiles, setPageSize, setPaperQuality, setCoverType } = usePrintContext()
    if (!(totalPageCount > 0 && pageSize > -1 && paperQuality > -1)) {
        return null
    }
    const cover_cost = (coverType > -1 ? cost.cover[coverType].price : 0)
    const price_per_page = cost.paper_sizes[pageSize].page_qualities[paperQuality].price_per_page
    const totalCost = (totalPageCount * price_per_page) + cover_cost
    function addToCart() {
        const cart = shoppingCartService.addPrintItem({
            files,
            page_size: cost.paper_sizes[pageSize].label,
            paper_quality: cost.paper_sizes[pageSize].page_qualities[paperQuality].label,
            cover_type: coverType > -1 ? cost.cover[coverType].label : '',
            pages: totalPageCount,
            price_per_page,
            cover_cost,
            item_total: totalCost
        })
        SetData({ ...data, ...{ cart } })
        cartModalRef.current?.present()
        setFiles([])
        setPageSize(-1)
        setPaperQuality(-1)
        setPaperQuality(-1)
        setCoverType(-1)
    }
    return (
        <IonGrid>
            <IonRow>
                <IonCol size="6">
                    <IonImg src={appSubDir + "assets/img/print.webp"} alt="Print Cost" />
                </IonCol>
                <IonCol size="6">
                    <div className='page-count'>
                        <h3>Your Printing Cost</h3>
                        <p>Your cost is auto generated. Total cost is</p>
                        <h1>{totalCost} Taka</h1>
                        <br />
                        <IonButton color='tertiary' className='btn-order' onClick={addToCart} ><IonText>কার্টে যোগ করুন</IonText></IonButton>
                        <IonButton color='danger' className='btn-order' onClick={() => {
                            addToCart()
                            checkoutModalRef.current?.present()
                        }}><IonText color='light'>অর্ডার কনর্ফাম করুন</IonText></IonButton>
                    </div>
                </IonCol>
            </IonRow>
        </IonGrid>
    );
}
export default CostSection;
