import { IonButton, IonCol, IonContent, IonFooter, IonHeader, IonItem, IonModal, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import Header from '../../components/Header/Header';
import { onScroll } from '../../services/sticky-menu.service';
import Footer from '../../components/Footer';
import { useEffect, useRef, useState } from 'react';
import { fetchCategories, getCategory, localCategories } from '../../services/products/all-category.service';
import { useParams } from 'react-router';
import { appSubDir } from '../../configs/app';
import { fetchCategoryProducts, getCategoryProducts } from '../../services/products/category-products.service';
import ProductsGrid from '../../components/Products/ProductsGrid';
import Pagination from '../../components/Pagination';
import { useTranslation } from 'react-i18next';
type CustomParam = {
    catID: string
}
const Category: React.FC = () => {
    const {t} = useTranslation()
    const { catID } = useParams() as CustomParam
    const categories = localCategories()
    const productResponse = getCategoryProducts(parseInt(catID))
    const [item, setItem] = useState(getCategory(categories,parseInt(catID)))
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState(0)
    const [loading, setLoadling] = useState(false)
    const [items, setItems] = useState(productResponse ? productResponse.data : null)
    const modal = useRef<HTMLIonModalElement>(null)
    function loadProducts(page: number) {
        const local = getCategoryProducts(parseInt(catID), page)
        if (local) {
            setItems(local.data)
        } else {
            setLoadling(true)
        }
        fetchCategoryProducts(parseInt(catID), page).then((res) => {
            if (res) {
                // if (res.current_page === 1 || !items) {
                //     setItems(res.data)
                //     setLastPage(res.last_page)
                // } else {
                //     setItems([...items, ...res.data])
                // }
                setItems(res.data)
                setLastPage(res.last_page)
                setPage(res.current_page)
            }
        }).finally(() => setLoadling(false))
    }
    useEffect(() => {
        const categories = localCategories()
        if (categories.length > 0) {
            setItem(getCategory(categories,parseInt(catID)))
        } else {
            fetchCategories().then((items) => {
                setItem(getCategory(items,parseInt(catID)))
            })
        }
        loadProducts(1)
    }, [catID])
    return (
        <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='ion-padding bg-content'>
                    <div className='page-container'>
                        {!item && !loading && <div className='text-center'>
                            <h1 style={{ marginTop: 100, marginBottom: 100 }}>Sorry ! Nothing Found</h1>
                            <IonButton routerLink={appSubDir} fill='outline'>
                                Go To Home
                            </IonButton>
                        </div>}
                        {item && <div className='page-container'>
                            <div className='title-bar'>
                                <h6>{t('category')}</h6>
                                <h1>{item?.title}</h1>
                                {item.subjects.length > 0 && <IonButton onClick={() => modal.current?.present()} fill='outline'>All Subjects</IonButton>}
                            </div>
                            <div style={{ marginTop: 30 }}>
                                {items && <ProductsGrid title='Products' products={items} hideHeader={true} />}
                                {lastPage > 1 && <Pagination active={page} disabled={loading} onClick={loadProducts} buttonCount={lastPage} />}
                            </div>
                            {item.subjects.length > 0 && <IonModal ref={modal}>
                                <IonHeader>
                                    <IonToolbar>
                                        <IonTitle>Sub Categories</IonTitle>
                                        {window.screen.width > 425 && <IonButton onClick={() => modal.current?.dismiss()} slot='end' fill='clear'>Close</IonButton>}
                                    </IonToolbar>
                                </IonHeader>
                                <IonContent>
                                    {item.subjects.map((item, index) => <IonItem key={index} routerLink={appSubDir + "categories/" + item.category_id + "/subjects/" + item.id}>{item.title}</IonItem>)}
                                </IonContent>
                                {window.screen.width <= 425 && <IonFooter>
                                    <IonButton onClick={() => modal.current?.dismiss()} expand='block' fill='clear'>Close</IonButton>
                                </IonFooter>}
                            </IonModal>}
                        </div>}
                    </div>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default Category;
