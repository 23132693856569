import { useEffect, useRef, useState } from 'react';
import { appSubDir, baseUrl } from '../../configs/app';
import { IonButton, IonIcon, IonItem, IonLabel } from '@ionic/react';
import Category, { Subject } from '../../types/category.type';
import { chevronForward, paperPlane } from 'ionicons/icons';
import "./AllCategories.scss"
import { fetchCategories, localCategories } from '../../services/products/all-category.service';
import ProgressiveImg from '../ProgressiveImg';
import AllCategoriesIcon from '../icons/AllCategoriesIcon';
import { useTranslation } from 'react-i18next';

const AllCategories: React.FC = () => {
    const { t } = useTranslation();
    const subjectContainer = useRef<HTMLDivElement>(null)
    const sidebarContainer = useRef<HTMLDivElement>(null)
    const [items, setItems] = useState(localCategories())
    const [subjects, setSubjects] = useState([] as Subject[])
    useEffect(() => {
        fetchCategories().then((categories) => {
            setItems(categories)
        })
    }, [])
    function onCategoryHover(item: Category) {
        setSubjects(item.subjects)
        if (subjectContainer && item.subjects.length > 0) {
            subjectContainer.current?.classList.add('show')
        } else {
            subjectContainer.current?.classList.remove('show')
        }
    }
    function onSidebarMouseLeave() {
        if (subjectContainer) {
            subjectContainer.current?.classList.remove('show')
        }
    }
    function getCatLink(cat: Category) {
        return appSubDir + "categories/" + cat.id
    }
    return <div ref={sidebarContainer} id='category-sidebar' onMouseLeave={onSidebarMouseLeave}>
        <div className='title'>{t('all_categories')}</div>
        <div className="categories">
            {items && items.length > 0 && items.slice(0, 7).map((item, index) => <IonItem
                key={index}
                routerLink={window.screen.width <= 786 ? undefined : getCatLink(item)}
                onMouseEnter={() => onCategoryHover(item)} button={true}>
                <ProgressiveImg src={baseUrl + `storage/uploads/${item.image}.webp`} width={30} slot='start'/>
                <IonLabel>{item.title}</IonLabel>
                {window.screen.width <= 786 && <IonButton slot='end' fill='clear' routerLink={getCatLink(item)}><IonIcon icon={paperPlane} /></IonButton>}
                {item.subjects.length > 0 && <IonIcon slot='end' icon={chevronForward} />}
            </IonItem>)}
            <IonItem
                routerLink={appSubDir+"categories"}>
                <AllCategoriesIcon width={30} slot='start'/>
                <IonLabel>{t('all_categories')}</IonLabel>
            </IonItem>
        </div>
        <div ref={subjectContainer} className="subjects">
            {subjects.length > 0 && subjects.map((item, index) => <IonItem key={index}
                routerLink={appSubDir + "categories/" + item.category_id + "/subjects/" + item.id}>
                <IonLabel>{item.title}</IonLabel>
            </IonItem>)}
        </div>
    </div>
};

export default AllCategories;
