import { IonButton, IonCol, IonContent, IonRow } from '@ionic/react';
import Header from '../../components/Header/Header';
import { onScroll } from '../../services/sticky-menu.service';
import Footer from '../../components/Footer';
import { useEffect, useState } from 'react';
import { fetchAllProducts, getAllProducts } from '../../services/products/all-products.service';
import { appSubDir } from '../../configs/app';
import ProductsGrid from '../../components/Products/ProductsGrid';
import Pagination from '../../components/Pagination';
import AllAuthors from '../../components/Products/AllAuthors';
import AllCategories from '../../components/Products/AllCategories';
import { useTranslation } from 'react-i18next';

const Shop: React.FC = () => {
    const { t } = useTranslation();
    const [loading, setLoadling] = useState(false)
    const productResponse = getAllProducts()
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState(0)
    const [items, setItems] = useState(productResponse ? productResponse.data : null)
    useEffect(() => {
        loadProducts(1)
    }, [])
    function loadProducts(page: number) {
        const local = getAllProducts(page)
        if (local) {
            setItems(local.data)
        } else {
            setLoadling(true)
        }
        fetchAllProducts(page).then((res) => {
            if (res) {
                setItems(res.data)
                setLastPage(res.last_page)
                setPage(res.current_page)
            }
        }).finally(() => setLoadling(false))
    }
    return (
        <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                    <div className='page-container'>
                        {!items && !loading && <div className='text-center'>
                            <h1 style={{ marginTop: 100, marginBottom: 100 }}>Sorry ! Nothing Found</h1>
                            <IonButton routerLink={appSubDir} fill='outline'>
                                Go To Home
                            </IonButton>
                        </div>}
                        {items && <div className='page-container'>
                            <div className='title-bar'>
                                <h6>Shop</h6>
                                <h1>All Products</h1>
                                <AllAuthors title='All Authors'/>
                                <AllCategories title={t('all_categories')}/>
                            </div>
                            <div style={{ marginTop: 30 }}>
                                {items && <ProductsGrid title='Products' products={items} hideHeader={true} />}
                                {lastPage > 1 && <Pagination active={page} disabled={loading} onClick={loadProducts} buttonCount={lastPage} />}
                            </div>
                        </div>}
                    </div>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default Shop;
