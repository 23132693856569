
import React from 'react';
import {
    IonItem, IonLabel, IonRadioGroup, IonRadio, IonImg, IonGrid, IonRow, IonCol, IonListHeader
} from '@ionic/react';
import { usePrintContext } from '../../contexts/print.context';
import { appSubDir } from '../../configs/app';


const PaperQuality: React.FC = () => {
    const { cost, pageSize, paperQuality, setPaperQuality } = usePrintContext()
    if (pageSize < 0) {
        return null
    }
    return (
        <IonRadioGroup value={paperQuality} onIonChange={e => setPaperQuality(parseInt(e.detail.value))}>
            <IonListHeader>
                <IonLabel>Select Paper Quality</IonLabel>
            </IonListHeader>
            <IonGrid >
                <IonRow>
                    {cost.paper_sizes[pageSize].page_qualities.map((item, index) => (<IonCol key={index}>
                        <IonItem>
                            <IonLabel>{item.label} - {item.price_per_page} Tk / Page</IonLabel>
                            <IonRadio slot="start" value={index} />
                        </IonItem>
                    </IonCol>))}
                </IonRow>
            </IonGrid>
        </IonRadioGroup>
    )
};

const CoverOptions: React.FC = () => {
    const { cost, coverType, setCoverType } = usePrintContext()
    return (
        <IonRadioGroup value={coverType} onIonChange={e => setCoverType(parseInt(e.detail.value))}>
            <IonListHeader>
                <IonLabel>Select Cover</IonLabel>
            </IonListHeader>
            <IonGrid>
                <IonRow>
                    <IonItem>
                        <IonLabel>Not Required</IonLabel>
                        <IonRadio slot="start" value={-1} />
                    </IonItem>
                    {cost.cover.map((item, index) => (<IonCol key={index}>
                        <IonItem>
                            <IonLabel>{item.label} - {item.price} Taka</IonLabel>
                            <IonRadio slot="start" value={index} />
                        </IonItem>
                    </IonCol>))}
                </IonRow>
            </IonGrid>
        </IonRadioGroup>
    )
};

const PaperSize: React.FC = () => {
    const { cost, pageSize, setPageSize } = usePrintContext()
    return (
        <IonRadioGroup value={pageSize} onIonChange={e => setPageSize(parseInt(e.detail.value))}>
            <IonListHeader>
                <IonLabel>Select Paper Size</IonLabel>
            </IonListHeader>

            {cost.paper_sizes.map((item, index) => {
                return (
                    <IonItem key={index}>
                        <IonLabel>{item.label}</IonLabel>
                        <IonRadio slot="start" value={index} />
                    </IonItem>
                )
            })}
        </IonRadioGroup>
    )
};
const RequirementsSection: React.FC = () => {
    const { totalPageCount } = usePrintContext()
    if (totalPageCount < 1) {
        return null
    }
    return (
        <IonGrid>
            <IonRow>
                <IonCol size="7">
                    <h1>Print Requirements</h1>
                    <p>Select your necessary requirements for printing</p>
                    <PaperSize />
                    <PaperQuality />
                    <CoverOptions />
                </IonCol>
                <IonCol size="5">
                    <IonImg src={appSubDir + "assets/img/requirements.webp"} alt="Print Cost" />
                </IonCol>
            </IonRow>
        </IonGrid>
    )
};
export default RequirementsSection;
