import { useEffect, useState } from 'react';
import { fetchProducts,getProducts } from '../../services/products/products.service';
import ProductsGrid from '../Products/ProductsGrid';
import { appSubDir } from '../../configs/app';
import { useTranslation } from 'react-i18next';

const params = new URLSearchParams("best_selling=best_selling&random_order=true&img=web&items_per_page=12")
const BestSellingComponent: React.FC = () => {
    const {t} = useTranslation()
    const localProduct = getProducts(params)
    const [items, setItems] = useState(localProduct?.data)
    useEffect(() => {
        fetchProducts(params).then(product => {
            if (product) {
                setItems(product.data)
            }
        })
    }, [])
    if(!items){
        return null
    }
    return (
        <ProductsGrid title={t('best_selling')} products={items} routerLink={appSubDir+"shop"}/>
    );
};

export default BestSellingComponent;
