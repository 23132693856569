import React from 'react';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Shop from './pages/products/Shop';
import FlashSale from './pages/products/FlashSale';
import NewArrival from './pages/products/NewArrival';
import Product from './pages/products/Product';
import Category from './pages/products/Category';
import Author from './pages/products/Author';
import Subject from './pages/products/Subject';
import PaymentVerify from './pages/payments/PaymentVerify';
import PaymentSuccess from './pages/payments/PaymentSuccess';
import PaymentFailed from './pages/payments/PaymentFailed';
import PaymentCancelled from './pages/payments/PaymentCancelled';
import ResellerDashboard from './pages/account/ResellerDashboard';
import Search from './pages/products/Search';
import PrintPage from './pages/PrintPage';
import CommingSoon from './pages/CommingSoon';
import GuestUserInvoice from './pages/GuestUserInvoice';
import OrderPlaced from './pages/payments/OrderPlaced';
import Invoices from './pages/account/Invoices';
import InvoiceView from './pages/account/Invoice';
import WishlistPage from './pages/products/WishlistPage';
import ComparePage from './pages/ComparePage';

// Define a type for the route configuration
interface RouteConfig {
    path: string;
    Component: React.ComponentType<any>;
}

// Define all routes here using the RouteConfig type
export const publicRoutes: RouteConfig[] = [
    { path: "", Component: Home },
    { path: "contact", Component: Contact },
    { path: "shop", Component: Shop },
    { path: "flash-sale", Component: FlashSale },
    { path: "new-arrival", Component: NewArrival },
    { path: "product/:slug/item-:ID", Component: Product },
    { path: "search/:searchKey", Component: Search },
    { path: "categories", Component: CommingSoon },
    { path: "categories/:catID", Component: Category },
    { path: "authorsz", Component: CommingSoon },
    { path: "authors/:authorID", Component: Author },
    { path: "categories/:catID/subjects/:subjectID", Component: Subject },
    { path: "printing-service", Component: PrintPage },
    { path: "compare", Component: ComparePage },
    { path: "order-placed", Component: OrderPlaced },
    { path: "guest-invoice/:encryptedInvoiceID", Component: GuestUserInvoice }
];
export const privateRoutes: RouteConfig[] = [

    { path: "payment-verify/:valID", Component: PaymentVerify },
    { path: "payment/success", Component: PaymentSuccess },
    { path: "payment/failed", Component: PaymentFailed },
    { path: "payment/cancelled", Component: PaymentCancelled },
    { path: "reseller-dashboard", Component: ResellerDashboard },
    { path: "printing-service", Component: PrintPage },
    { path: "wishlist", Component: WishlistPage },
    { path: "invoices", Component: Invoices },
    { path: "invoices/:invoiceID", Component: InvoiceView }
    // Additional routes can be added here
];
