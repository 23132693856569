import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en.json';
import bnTranslation from './locales/bn.json';

const resources = {
  en: {
    translation: enTranslation
  },
  bn: {
    translation: bnTranslation
  }
};

// Check if there's a saved language in local storage
const savedLanguage = localStorage.getItem('selectedLanguage') || 'en';
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: savedLanguage, // default language
    fallbackLng: 'en', // fallback language if the translation is not available
    interpolation: {
      escapeValue: false // React already does escaping
    }
  });

export default i18n;
