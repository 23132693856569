import {
    IonButton, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonList, IonModal,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle, IonToolbar
} from "@ionic/react";
import { closeCircle, list, logoFacebook, logoYoutube, phonePortrait, shapes, storefront, globe } from "ionicons/icons";
import { useRef } from "react";
import { appSubDir, baseUrl } from "../../configs/app";
import AllCategoryModal from "./AllCategoryModal";
import { defaultAuthData } from "../../services/auth-context.service";
import { authModalRef } from "../Auth/AuthModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { useAppContext } from "../../services/app-context.service";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

export let quickLinksModalRef: React.RefObject<HTMLIonModalElement>
const QuickLinksModal: React.FC = () => {
    const { t, i18n } = useTranslation(); // Destructure t and i18n from useTranslation hook
    const { user } = useAppContext()
    quickLinksModalRef = useRef<HTMLIonModalElement>(null)
    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng) // Change the language using i18n instance
            .then(() => {
                localStorage.setItem('selectedLanguage', lng); // Save selected language to local storage
            }).catch((err) => {
                console.error('Language change error:', err);
            })
    }

    return <>
        <IonButton fill='clear' onClick={() => quickLinksModalRef.current?.present()}>
            <IonIcon size='large' icon={list} />
        </IonButton>
        <IonModal ref={quickLinksModalRef} id='cart-modal'>
            <IonHeader>
                <IonToolbar color="light">
                    <IonTitle>{t('quick_links')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light" className='ion-padding' style={{ overflow: "auto" }}>
                <IonList>
                    <IonItem href={"https://www.facebook.com/nilekhetbsps"} target="_blank">
                        <IonIcon slot="start" icon={logoFacebook} />
                        <IonText>{t('facebook_page')}</IonText>
                    </IonItem>
                    <IonItem href={"https://www.facebook.com/groups/nilekhetbsps/"} target="_blank">
                        <IonIcon slot="start" icon={logoFacebook} />
                        <IonText>{t('facebook_group')}</IonText>
                    </IonItem>
                    <IonItem href="https://youtube.com/@nilekhetbsps" target="_blank">
                        <IonIcon slot="start" icon={logoYoutube} />
                        <IonText>{t('youtube_channel')}</IonText>
                    </IonItem>
                    {window.screen.width <= 600 && <>
                        <IonItem routerLink={appSubDir + "shop"}>
                            <IonIcon slot="start" icon={storefront} />
                            <IonText>{t('all_products')}</IonText>
                        </IonItem>
                        <AllCategoryModal />
                        <IonItem routerLink={appSubDir + "about"}>
                            <IonIcon slot="start" icon={logoYoutube} />
                            <IonText>{t('about_us')}</IonText>
                        </IonItem>
                        <IonItem routerLink={appSubDir + "contact"}>
                            <IonIcon slot="start" icon={phonePortrait} />
                            <IonText>{t('contact')}</IonText>
                        </IonItem>
                        {!user.token && <IonItem button={true} onClick={() => {
                            quickLinksModalRef.current?.dismiss().then(() => {
                                defaultAuthData.role_id = 2
                                authModalRef.current?.present()
                            })
                        }}>
                            <IonText style={{ color: "rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.54)" }} slot='start'><FontAwesomeIcon fontSize={25} icon={faUserCircle} /></IonText>
                            <IonText>Reseller Login</IonText>
                        </IonItem>}
                    </>}
                    {/* {t('language')} Selection Item */}
                    <IonItem>
                        <IonIcon slot="start" icon={globe} />
                        <IonText>{t('language_change')}</IonText>
                        <IonSelect slot="end" value={i18n.language} onIonChange={(e) => changeLanguage(e.detail.value)}>
                            <IonSelectOption value="en">English</IonSelectOption>
                            <IonSelectOption value="bn">বাংলা</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem href={baseUrl + "seller-login"} target="_blank">
                        <IonIcon slot="start" icon={shapes} />
                        <IonText>{t('seller_login')}</IonText>
                    </IonItem>
                </IonList>
            </IonContent>
            <IonFooter>
                {window.screen.width <= 600 && <IonButton fill='clear' expand="block" onClick={() => quickLinksModalRef.current?.dismiss()}>
                    <IonIcon slot="start" icon={closeCircle} /> {t('close')}
                </IonButton>}
            </IonFooter>
        </IonModal>
    </>
};
export default QuickLinksModal
