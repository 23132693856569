import { useEffect, useState } from 'react';
import { appSubDir } from '../../configs/app';
import Product from '../../types/product.type';
import ProductsGrid from './ProductsGrid';
import { fetchSubjectProducts, getSubjectProducts } from '../../services/products/subject-wise-products.service';
type Props = {
    product: Product
}
const SubjectProductsGrid: React.FC<Props> = ({ product }) => {
    const [items, setItems] = useState(product.subject && getSubjectProducts(product.subject.id)?.data)
    function fetchData() {
        if (!product.subject) {
            return
        }
        fetchSubjectProducts(product.subject.id)
            .then(res => {
                if (res) {
                    setItems(res.data.filter((items, index) => items.id != product.id))
                }
            })
    }
    useEffect(() => {
        fetchData()
    }, [product])
    if (!items || items.length < 1 || !product.category || !product.subject) {
        return null
    }
    return (
        <ProductsGrid title={product.subject.title} products={items} routerLink={appSubDir + "categories/" + product.category.id + "/subjects/" + product.subject.id} />
    );
};

export default SubjectProductsGrid;
