import { IonButton } from '@ionic/react';
import { SimpleProduct } from '../../types/product.type';
import './ProductsVerticalBox.scss'
import ProductListItem from './ProductListItem';
import { appSubDir } from '../../configs/app';
type Props = {
    title: string
    routerLink?: string
    products: SimpleProduct[]
}
const ProductsVerticalBox: React.FC<Props> = ({ title, products, routerLink }) => {
    return (
        <section className='v-products-box'>
            <div className='title'>{title}</div>
            {products && products.map((item, index) => (<ProductListItem key={index} product={item} />))}
            <div className='btn-container'>
                {routerLink ? <IonButton routerLink={routerLink} fill='outline' color="primary">View All</IonButton> : <IonButton routerLink={appSubDir+"shop"} fill='outline' color="primary">All Products</IonButton>}
            </div>
        </section>
    );
};

export default ProductsVerticalBox;
