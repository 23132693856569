// frontend/src/components/ProductCompareButton.tsx

import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonIcon,
  IonSpinner,
  IonText,
  IonToast,
  IonActionSheet,
} from '@ionic/react';
import { list, checkmarkCircle, removeCircle, eye } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';
import {
  addCompareProductId,
  getCompareProductIds,
  removeCompareProductId,
} from '../../services/products/compare.service';

type Props = {
  id: number;
};

const ProductCompareButton: React.FC<Props> = ({ id }) => {
  const [isInCompare, setIsInCompare] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [showActionSheet, setShowActionSheet] = useState<boolean>(false);
  const history = useHistory();

  // Check if the product is already in the compare list on component mount
  useEffect(() => {
    const compareIds = getCompareProductIds();
    setIsInCompare(compareIds.includes(id));
  }, [id]);

  /**
   * Handle adding the product to the compare list
   */
  const handleAddToCompare = () => {
    setButtonDisabled(true);
    const added = addCompareProductId(id);
    if (added) {
      setIsInCompare(true);
      setToastMessage('Added to comparison.');
      setShowToast(true);
    } else {
      // Limit reached
      setToastMessage('You can only compare up to 4 products.');
      setShowToast(true);
    }
    setButtonDisabled(false);
  };

  /**
   * Handle removing the product from the compare list
   */
  const handleRemoveFromCompare = () => {
    setButtonDisabled(true);
    removeCompareProductId(id);
    setIsInCompare(false);
    setToastMessage('Removed from comparison.');
    setShowToast(true);
    setButtonDisabled(false);

  };

  /**
   * Handle viewing the comparison page
   */
  const handleViewComparison = () => {
    // Update the URL with the current compare IDs
    const updatedCompareIds = getCompareProductIds();
    if (updatedCompareIds.length > 0) {
      history.push(`/compare?ids=${updatedCompareIds.join(',')}`);
    } else {
      history.push('/compare');
    }
  };

  /**
   * Toggle the action sheet visibility
   */
  const toggleActionSheet = () => {
    setShowActionSheet(true);
  };

  return (
    <>
      <IonButton
        onClick={toggleActionSheet}
        disabled={buttonDisabled}
        fill="outline"
        color={isInCompare ? 'success' : 'primary'}
      >
        {buttonDisabled ? (
          <IonSpinner slot="start" />
        ) : isInCompare ? (
          <IonIcon slot="start" icon={checkmarkCircle} />
        ) : (
          <IonIcon slot="start" icon={list} />
        )}
        <IonText>
          {buttonDisabled
            ? isInCompare
              ? 'Updating...'
              : 'Adding to Compare'
            : isInCompare
            ? 'Remove from Compare'
            : 'Add to Compare'}
        </IonText>
      </IonButton>

      {/* Action Sheet for additional options */}
      <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        buttons={[
          isInCompare
            ? {
                text: 'Remove from Compare',
                role: 'destructive',
                icon: removeCircle,
                handler: () => {
                  handleRemoveFromCompare();
                },
              }
            : {
                text: 'Add to Compare',
                icon: list,
                handler: () => {
                  handleAddToCompare();
                },
              },
          {
            text: 'View Comparison',
            icon: eye,
            handler: () => {
              handleViewComparison();
            },
          },
          {
            text: 'Cancel',
            icon: 'close',
            role: 'cancel',
          },
        ]}
      />

      {/* Toast for feedback */}
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000}
        position="bottom"
      />
    </>
  );
};

export default ProductCompareButton;
