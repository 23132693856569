import axios from "axios"
import { apiBaseUrl } from "../configs/app"
import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/build/pdf.worker.min.mjs';

function onPrint() {
    const printView = document.getElementById('print-view')
    if (printView) {
        let printableContent: string = ""
        document.querySelectorAll('.print-section').forEach((element) => {
            printableContent += element.innerHTML
        })
        printView.innerHTML = printableContent
    }
    window.print()
}
export function clearPrintView() {
    const printView = document.getElementById('print-view')
    if (printView) {
        printView.innerHTML = ""
    }
}
export function uploadPdf(file:File){

    const formData = new FormData();
    formData.append('pdf', file);

    return axios.post(apiBaseUrl+"upload-pdf", formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
export async function getPageCount(file:File){
    const arrayBuffer = await file.arrayBuffer();
    const pdf = await pdfjsLib.getDocument(arrayBuffer).promise;
    return pdf.numPages;
}
export default onPrint
