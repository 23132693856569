import { Link } from 'react-router-dom';
import { appSubDir, baseUrl } from '../../configs/app';
import { IonCol, IonRow } from '@ionic/react';
import CartModal from '../Shop/CartModal';
import AuthModal from '../Auth/AuthModal';
import WishlistButton from '../Shop/WishlistButton';
import './Header.scss'
import BottomMenu from './MobileMenu';
import SearchBar from './SearchBar';
import QuickLinksModal from './QuickLinksModal';
import { useAppContext } from '../../services/app-context.service';

const Header: React.FC = () => {
    const appData = useAppContext()
    const { name, logo } = appData.settings.app
    const { innerWidth } = appData

    return <>
        {/* <TopBar /> */}
        <header>
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1'>
                    <IonRow className='desktop'>
                        <IonCol size='12' sizeMd='3'>
                            <Link to={appSubDir} className='author'>
                                {!!logo && <img src={baseUrl + "storage/uploads/" + logo + ".webp"} alt={name + ' Logo'} />}
                                <h1>{name}</h1>
                            </Link>
                        </IonCol>
                        <IonCol size='12' sizeMd='6'>
                            <SearchBar />
                        </IonCol>
                        <IonCol size='12' sizeMd='3'>
                            <div className='buttons'>
                                <WishlistButton />
                                <CartModal />
                                <AuthModal />
                                <QuickLinksModal />
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow className='tab'>
                        <IonCol size='6'>
                            <Link to={appSubDir} className='author'>
                                <img src={baseUrl + "storage/uploads/" + appData.settings.app.logo + ".webp"} alt='Author Logo' />
                                <h1>{appData.settings.app.name}</h1>
                            </Link>
                        </IonCol>
                        <IonCol size='6'>
                            <div className='buttons'>
                                <WishlistButton />
                                <CartModal />
                                <AuthModal />
                                <QuickLinksModal />
                            </div>
                        </IonCol>
                        <IonCol size='12'>
                            <SearchBar />
                        </IonCol>
                    </IonRow>
                    <IonRow className='mobile'>
                        <Link to={appSubDir} className='author'>
                            {!!logo && <img src={baseUrl + "storage/uploads/" + logo + ".webp"} alt={name + ' Logo'} />}
                            <h1>{name}</h1>
                        </Link>
                    </IonRow>
                </IonCol>
            </IonRow>
        </header>
        {innerWidth <= 600 && <BottomMenu />}
    </>
};

export default Header;
